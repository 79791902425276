import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Tag, Tooltip } from 'antd'
import { CopyOutlined } from '@ant-design/icons'
import { showToast } from '@/utils/toast'
import getIconByUrl from '@/utils/getIconByUrl'
import { toggleWizardModal, updateWizard } from '@/store/playgrounds/actions'
import ProLabel from '@/components/ProLabel'
import ProBadge from '@/assets/images/plans/pro-badge.png'
import { Container } from './styles'

const DataSourceCard = ({ dataSource, showExtras, showDescription }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const [themeIcon, setThemeIcon] = useState(null)
  const [typeIcon, setTypeIcon] = useState(null)
  const [formatIcon, setFormatIcon] = useState(null)
  const [engineIcon, setEngineIcon] = useState(null)

  const { userProfile } = useSelector((state) => state.users)
  const canViewDataSourceTooltip = userProfile?.permissions?.includes('playgrounds.view_data_source_tooltip')

  const { wizard } = useSelector((state) => state.playgrounds)

  const handleClick = () => {
    if (location?.pathname === '/playgrounds') {
      dispatch(updateWizard({ preDataSource: dataSource }))
      dispatch(toggleWizardModal(true))
      return
    }

    if (wizard?.type === '0') {
      dispatch(updateWizard({ preDataSource: dataSource }))
      return
    }

    navigate(`/playgrounds?data_source=${dataSource?.id}`)
  }

  useEffect(() => {
    if (!dataSource) return

    getIconByUrl({
      iconUrl: dataSource?.theme?.logo_url || 'ant-FileTextOutlined',
      className: 'theme-icon',
      onReady: setThemeIcon,
    })

    getIconByUrl({
      iconUrl: dataSource?.subtype?.logo_url || 'ant-FileTextOutlined',
      className: 'type-icon',
      onReady: setTypeIcon,
    })

    if (dataSource?.format) {
      getIconByUrl({
        iconUrl: dataSource?.format?.logo_url || 'ant-FileTextOutlined',
        className: 'format-icon',
        onReady: setFormatIcon,
      })
    }

    if (dataSource?.engine) {
      getIconByUrl({
        iconUrl: dataSource?.engine?.logo_url || 'ant-FileTextOutlined',
        className: 'engine-icon',
        onReady: setEngineIcon,
      })
    }
  }, [dataSource])

  return (
    <Container className="datasource-card-container" onClick={handleClick}>
      <div className="content">
        <Tooltip
          title={
            canViewDataSourceTooltip && (
              <span
                onClick={(evt) => {
                  evt.stopPropagation()
                  navigator.clipboard.writeText(dataSource?.id)
                  showToast('ID copied to clipboard')
                }}
              >
                ID: {dataSource?.id} <CopyOutlined style={{ cursor: 'pointer' }} />
              </span>
            )
          }
          placement="bottom"
        >
          <div className="icon-container">
            {themeIcon}

            {dataSource?.status === 'draft' ? (
              <Tag className="tag draft">Draft</Tag>
            ) : (
              dataSource?.is_beta && <Tag className="tag beta">Beta</Tag>
            )}
          </div>
        </Tooltip>

        <div className="header">
          <div className="title-container">
            <h5 className="title">
              {typeIcon} {dataSource?.name}
            </h5>

            {showDescription && dataSource?.short_description_html && (
              <div
                className="description"
                dangerouslySetInnerHTML={{
                  __html: dataSource?.short_description_html,
                }}
              />
            )}

            <div className="tags-container">
              <Tag className="tag">{dataSource?.theme?.value}</Tag>
              <Tag className="tag">{dataSource?.subtype?.value}</Tag>
              <Tag className="tag">{dataSource?.format?.value || dataSource?.engine?.value}</Tag>
            </div>
          </div>

          <span className="right-section">
            {showExtras && (
              <>
                {dataSource?.is_pro ? <ProLabel /> : <span />}

                <span>
                  {formatIcon}
                  {engineIcon}
                </span>
              </>
            )}
          </span>
        </div>

        {dataSource?.is_pro && !showExtras && (
          <div className="pro-icon">
            <img className="pro-badge" src={ProBadge} alt="PRO user" />
          </div>
        )}
      </div>
    </Container>
  )
}

export default DataSourceCard
