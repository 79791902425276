import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { Input } from 'antd'
import { MailTwoTone, SmileTwoTone, LockTwoTone } from '@ant-design/icons'

import Button from '@/components/Button'
import PasswordInput from '@/components/PasswordInput'
import { getEmailRules } from '@/helpers/formValidation'
import { signupWithVoucher } from '@/store/vouchers/actions'
import { LogoHeader, Form } from './styles'

const SignUpForm = ({ voucher }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [passwordError, setPasswordError] = useState('')

  const { currentModule } = useSelector((state) => state.modules)
  const { error: voucherError } = useSelector((state) => state.vouchers)

  const onSuccess = () => {
    if (currentModule) {
      navigate(`/project/${currentModule?.id}`, { replace: true })
      return
    }
  }

  const onSubmit = async (data) => {
    const auxData = {
      ...data,
      voucher_code: voucher,
    }

    setIsLoading(true)
    await dispatch(signupWithVoucher(auxData, onSuccess))
    setIsLoading(false)
  }

  return (
    <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={handleSubmit(onSubmit)} autoComplete="off">
      {!voucher && (
        <LogoHeader>
          <h3>Create your free account</h3>
        </LogoHeader>
      )}

      <div className="form-row">
        <Controller
          name="first_name"
          defaultValue=""
          control={control}
          rules={{ required: 'First name is required' }}
          render={({ field }) => (
            <Input
              {...field}
              type="text"
              size="large"
              status={errors.first_name ? 'error' : null}
              placeholder="First name"
              prefix={<SmileTwoTone />}
            />
          )}
        />

        <Controller
          name="last_name"
          defaultValue=""
          control={control}
          rules={{ required: 'Last name is required' }}
          render={({ field }) => (
            <Input
              {...field}
              type="text"
              size="large"
              status={errors.last_name ? 'error' : null}
              placeholder="Last name"
              prefix={<SmileTwoTone />}
            />
          )}
        />
      </div>
      <Controller
        name="email"
        defaultValue=""
        control={control}
        rules={getEmailRules()}
        render={({ field }) => (
          <Input
            {...field}
            type="text"
            size="large"
            status={errors.email ? 'error' : null}
            placeholder="Email address"
            prefix={<MailTwoTone />}
          />
        )}
      />
      <div className="form-row">
        <Controller
          name="password"
          defaultValue=""
          control={control}
          rules={{
            required: 'Password is required',
          }}
          render={({ field }) => {
            const auxProps = { ...field }
            delete auxProps.ref

            return (
              <PasswordInput
                {...auxProps}
                type="password"
                size="large"
                status={errors.password ? 'error' : null}
                placeholder="Password"
                prefix={<LockTwoTone />}
                handleChange={setPasswordError}
              />
            )
          }}
        />

        <Controller
          name="repeat_password"
          defaultValue=""
          control={control}
          rules={{
            required: 'Re-enter your password',
            validate: (value) => value === watch('password') || "Passwords don't match",
          }}
          render={({ field }) => (
            <Input.Password
              {...field}
              type="password"
              size="large"
              status={errors.repeat_password ? 'error' : null}
              placeholder="Repeat password"
              prefix={<LockTwoTone />}
            />
          )}
        />
      </div>

      <div className="error-container">
        {errors && <p className="error-text">{errors[Object.keys(errors)?.[0]]?.message}</p>}
        {voucherError && <p className="error-text">{voucherError || errors.voucherCode.message}</p>}

        {!Object.keys(errors)?.length && passwordError && <p className="error-text">{passwordError}</p>}
      </div>

      <Button type="primary" size="large" htmlType="submit" disabled={passwordError} loading={isLoading}>
        Register
      </Button>

      <h5 className="bottom-text">
        Already have an account? <Link to={`/login${window?.location?.search}`}>Login</Link>
      </h5>
    </Form>
  )
}

export default SignUpForm
