import packageJson from '../../package.json'

const version = packageJson.version
const currentEnv = import.meta.env.REACT_APP_ENVIRONMENT
const isProduction = currentEnv === 'production'
const isDevelopment = currentEnv === 'development'
const isStaging = currentEnv === 'staging'

const hostname = window.location.hostname
const isDataWarsHostName = hostname?.endsWith('datawars.io')

const NAMESPACE_API_BASE_URL = isProduction
  ? 'https://api.prd.namespace.im/api/v1/'
  : 'https://api.dev.namespace.im/api/v1/'

const apiURL = isDataWarsHostName ? import.meta.env.REACT_APP_API_BASE_URL : NAMESPACE_API_BASE_URL
const profilesURL = import.meta.env.REACT_APP_PROFILES_BASE_URL
const teamsURL = import.meta.env.REACT_APP_TEAMS_BASE_URL
const getFlagURL = (code = 'xx') => `https://flagicons.lipis.dev/flags/4x3/${code}.svg`

export {
  version,
  currentEnv,
  isDevelopment,
  isStaging,
  isProduction,
  isDataWarsHostName,
  apiURL,
  profilesURL,
  teamsURL,
  getFlagURL,
}
