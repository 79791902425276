import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Button from '@/components/Button'
import Blob from '@/components/Blob'
import LabDeallocateButton from '@/pages/ModulePage/components/LabDeallocateButton'
// import RunningTime from '@/pages/ModulePage/components/RunningTime'
import { Content } from './styles'

const AllocatedModules = ({ allocatedModules, onClick }) => {
  const { uiConfig } = useSelector((state) => state.accounts)

  if (!allocatedModules?.length) return <></>

  return (
    <Content className="allocated-modules-container">
      <p className="text">Other running projects:</p>

      {allocatedModules?.map((m) => {
        let redirectUrl = ''

        if (m?.module_name) {
          redirectUrl = `/project/${m?.module_id}`
        }

        if (m?.playground_name) {
          redirectUrl = `/playgrounds/${m?.playground_id}?mode=edit`
        }

        if (m?.account_id) {
          redirectUrl = `/custom-projects/${m?.account_id}/${m?.playground_id}?mode=edit`
        }

        return (
          <div key={m?.module_id || m?.playground_id} className="allocated-module">
            <p className="module-name">
              <Blob color="green" text="Running project" size="small" addPulse />
              {m?.module_name || m?.playground_name}
            </p>

            <div className="actions">
              <div className="right-side">
                {uiConfig?.showGoToRunningModule && (
                  <Link to={redirectUrl} className="go-to-project-cta" onClick={onClick}>
                    <Button type="text">
                      {m?.module_name || m?.account_id ? 'Go to project' : 'Go to playground'}
                    </Button>
                  </Link>
                )}

                <LabDeallocateButton
                  labId={m?.lab_id}
                  labSessionId={m?.lab_session_id}
                  moduleAttemptId={m?.module_attempt_id}
                  text="Stop"
                  type="text"
                  disabled={false}
                />
              </div>
            </div>
          </div>
        )
      })}
    </Content>
  )
}

export default AllocatedModules
