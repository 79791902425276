import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RefinementList, ToggleRefinement, useInstantSearch } from 'react-instantsearch'
import { Collapse, Skeleton } from 'antd'
import ProLabel from '@/components/ProLabel'
import { Container } from './styles'

const Filters = () => {
  const { status: hitsStatus, results, indexUiState } = useInstantSearch()

  const { userProfile } = useSelector((state) => state.users)
  const canViewDraftDataSources = userProfile?.permissions?.includes('playgrounds.view_draft_data_source')

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    // show all facets
    const facets = document.querySelectorAll('.ais-RefinementList')
    facets.forEach((elem) => {
      elem.parentNode.parentNode.parentNode.parentNode.parentNode.style.display = 'initial'
    })

    // hide empty facets
    const emptyFacets = document.querySelectorAll('.ais-RefinementList--noRefinement')
    emptyFacets.forEach((elem) => {
      elem.parentNode.parentNode.parentNode.parentNode.parentNode.style.display = 'none'
    })

    // show all hierarchical facets
    const hierarchicalFacets = document.querySelectorAll('.ais-HierarchicalMenu')
    hierarchicalFacets.forEach((elem) => {
      elem.parentNode.style.display = 'initial'
    })

    // hide empty hierarchical facets
    const emptyHierarchicalFacets = document.querySelectorAll('.ais-HierarchicalMenu--noRefinement')
    emptyHierarchicalFacets.forEach((elem) => {
      elem.parentNode.style.display = 'none'
    })

    // hide empty disjunctive facets
    const togglePro = document.getElementById('toggle-pro')
    if (!results?.disjunctiveFacets?.length) return

    const hasProHits = results?.disjunctiveFacets?.[0]?.data?.true
    if (hasProHits) {
      togglePro.style.display = 'flex'
    } else {
      togglePro.style.display = 'none'
    }
  }, [results])

  useEffect(() => {
    if (indexUiState && hitsStatus !== 'loading' && hitsStatus !== 'stalled') {
      setIsLoading(false)
    }
  }, [indexUiState, hitsStatus])

  return (
    <Container className="search-filters">
      {isLoading ? (
        <Skeleton active title={false} paragraph={{ rows: 24 }} />
      ) : (
        <>
          {canViewDraftDataSources && (
            <div className="filter-category">
              <Collapse ghost defaultActiveKey={['1']}>
                <Collapse.Panel key="1" header={<p className="filter-title">Status</p>}>
                  <RefinementList attribute="status" operator="and" showMore />
                </Collapse.Panel>
              </Collapse>
            </div>
          )}

          <div className="filter-category">
            <Collapse ghost defaultActiveKey={['1']}>
              <Collapse.Panel key="1" header={<p className="filter-title">Category</p>}>
                <RefinementList attribute="theme.value" operator="and" showMore />
              </Collapse.Panel>
            </Collapse>
          </div>

          <div className="filter-category">
            <Collapse ghost defaultActiveKey={['1']}>
              <Collapse.Panel key="1" header={<p className="filter-title">Format</p>}>
                <RefinementList attribute="format.value" operator="and" showMore />
              </Collapse.Panel>
            </Collapse>
          </div>

          <div className="filter-category">
            <Collapse ghost defaultActiveKey={['1']}>
              <Collapse.Panel key="1" header={<p className="filter-title">Type</p>}>
                <RefinementList attribute="subtype.value" operator="and" showMore />
              </Collapse.Panel>
            </Collapse>
          </div>

          <div className="filter-category">
            <Collapse ghost defaultActiveKey={['1']}>
              <Collapse.Panel key="1" header={<p className="filter-title">Engine</p>}>
                <RefinementList attribute="engine.value" operator="and" showMore />
              </Collapse.Panel>
            </Collapse>
          </div>

          <ToggleRefinement
            id="toggle-pro"
            className="toggle-refinement"
            attribute="is_pro"
            label={
              <span className="toggle-label">
                <ProLabel /> datasets
              </span>
            }
          />
        </>
      )}
    </Container>
  )
}

export default Filters
