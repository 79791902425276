import { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Breadcrumb, Menu, Modal, Dropdown, Switch, Tooltip } from 'antd'
import {
  ExclamationCircleOutlined,
  HomeOutlined,
  SearchOutlined,
  UserOutlined,
  LogoutOutlined,
  SunOutlined,
  MoonOutlined,
  FlagOutlined,
  CreditCardOutlined,
  BuildOutlined,
  ContainerOutlined,
  CloseOutlined,
} from '@ant-design/icons'
import { ArrowRepeatAll24Regular, LayerDiagonal24Regular } from '@fluentui/react-icons'
import { isDataWarsHostName, profilesURL, teamsURL } from '@/helpers/env'
import { capitalize } from '@/utils/functions'
import Button from '@/components/Button'
import ProfilePicture from '@/components/ProfilePicture'
import ModuleTimer from '@/pages/ModulePage/components/ModuleTimer'
import GoProButton from './components/GoProButton'
import Logo from '@/assets/images/logo-white.svg'
import LogoSm from '@/assets/images/logo-sm.svg'
import MenuIcon from '@/assets/images/menu-icon.svg'
import StreakInfo from './components/StreakInfo'
import { toggleUpgradeToProModal, setAppTheme, toggleCustomReportIssueModal } from '@/store/app/actions'
import { createBilling, logout, toggleAuthModal } from '@/store/users/actions'
import { stopModuleAttempt } from '@/store/modules/actions'
import { toggleReportIssueModal } from '@/store/activities/actions'
import { Container } from './styles'

const Navbar = ({ isLoading }) => {
  const dispatch = useDispatch()
  const location = useLocation()

  const { theme } = useSelector((state) => state.app)
  const { uiConfig, currentAccount } = useSelector((state) => state.accounts)
  const { isAuthenticated, userProfile, emailValidationCodeIsValidated } = useSelector((state) => state.users)
  const { currentModule: module, currentObjective, isModuleAttemptLoading } = useSelector((state) => state.modules)

  const voucherFulfillmentIsEnabled = currentAccount?.config?.fulfillment?.voucher
  const enableCustomReportIssue = currentAccount?.config?.browse?.report_issue
  const customReportIssueButtonText = currentAccount?.whitelabel_config?.report_issue_button_text || 'Report an issue'

  const isPro = userProfile?.groups?.includes('Pro')
  const isCustomer = userProfile?.is_customer

  const canViewDarkMode = userProfile?.permissions?.includes('content.view_dark_mode')

  const isAccountOwner = userProfile?.accounts?.some((a) => a.role === 'owner')
  const isSuperAccountOwner = userProfile?.permissions?.includes('teams.super_account_owner')

  const isModulePage = location?.pathname?.startsWith('/project/') && location?.pathname?.length === 45
  const isAssessmentPage = location?.pathname?.startsWith('/assessment/') && location?.pathname?.length === 48
  const isCustomProject = module?.title?.name === 'Custom Projects'

  const moduleAttempt = module?.user_status?.last_module_attempt
  const isAttemptStarted = moduleAttempt?.status === 'started'
  const isAttemptFinished = moduleAttempt?.status === 'finished'
  const isAttemptActive = moduleAttempt?.is_active
  const isAssessment = module?.module_type === 'assessment'

  const [currentLeftMenuItem, setCurrentLeftMenuItem] = useState(location?.pathname)

  const handleLogout = () => {
    Modal.confirm({
      title: 'Sign out',
      content: 'Are you sure you want to log out?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes, sign out',
      cancelText: 'Keep working',
      onOk: () => dispatch(logout()),
      okButtonProps: { danger: true, type: 'primary', disabled: isLoading, loading: isLoading },
    })
  }

  const handleCreateBilling = async () => {
    const cb = (billingUrl) => window.open(billingUrl, '_blank')
    dispatch(createBilling(cb))
  }

  const handleOpenUpgradeToProModal = () => {
    Modal.destroyAll()
    dispatch(toggleUpgradeToProModal(true))
  }

  const handleStopModuleAttempt = () => {
    Modal.confirm({
      className: 'confirm-reset-activities-dialog',
      title: 'Quit assessment?',
      content: (
        <>
          <p className="subtitle">Are you sure you want to quit this assessment?</p>

          <blockquote>
            <span>PRO users enjoy an extra assessment attempt.</span>

            <p>
              <a onClick={handleOpenUpgradeToProModal} style={{ color: 'initial', fontWeight: 600 }}>
                <u>Upgrade Now</u>
              </a>
            </p>
          </blockquote>
        </>
      ),
      icon: <ExclamationCircleOutlined />,
      okText: 'Quit assessment',
      cancelText: 'Keep working',
      onOk: () =>
        dispatch(stopModuleAttempt(module?.id, module?.user_status?.last_module_attempt?.id, { reason: 'manual' })),
      okButtonProps: {
        danger: true,
        type: 'primary',
        disabled: isModuleAttemptLoading,
        loading: isModuleAttemptLoading,
      },
      maskClosable: true,
    })
  }

  const handleOpenCustomReportIssueModal = () => {
    dispatch(toggleCustomReportIssueModal(true))
  }

  const leftMenuItems = isAuthenticated
    ? [
        {
          key: 'dashboard',
          label: (
            <Link className="menu-item" to="/dashboard">
              Dashboard
            </Link>
          ),
        },
        {
          key: 'catalog',
          label: (
            <Link className="menu-item" to="/catalog?status=Published">
              Catalog
            </Link>
          ),
        },
        {
          key: 'playgrounds',
          label: (
            <Link className="menu-item" to="/playgrounds">
              Playgrounds
            </Link>
          ),
        },
        {
          key: 'datasets',
          label: (
            <Link className="menu-item" to="/playgrounds?tab=datasets">
              Datasets
            </Link>
          ),
        },
      ]
    : []

  const rightMenuItems = [
    isAuthenticated
      ? {
          key: 'profile',
          label: (
            <Dropdown
              trigger={['click']}
              overlayClassName={'navbar-right-menu'}
              menu={{
                items: [
                  ...(isDataWarsHostName
                    ? [
                        ...(isCustomer
                          ? [
                              {
                                key: 'billing',
                                label: (
                                  <a onClick={handleCreateBilling} className="menu-item">
                                    <CreditCardOutlined /> Billing
                                  </a>
                                ),
                              },
                            ]
                          : []),
                        ...(userProfile?.email_validated_at || emailValidationCodeIsValidated
                          ? [
                              {
                                key: 'publicProfile',
                                label: (
                                  <Link
                                    to={`${profilesURL}${userProfile?.username}`}
                                    target="_blank"
                                    className="menu-item"
                                  >
                                    <UserOutlined /> My public profile
                                  </Link>
                                ),
                              },
                            ]
                          : []),
                        {
                          key: 'reportIssue',
                          label: (
                            <a className="menu-item" onClick={() => dispatch(toggleReportIssueModal())}>
                              <FlagOutlined /> Report issue
                            </a>
                          ),
                        },
                      ]
                    : []),
                  ...(voucherFulfillmentIsEnabled
                    ? [
                        {
                          key: 'redeemVoucher',
                          label: (
                            <Link to="/redeem-voucher" className="menu-item rotated">
                              <ContainerOutlined />
                              Redeem voucher
                            </Link>
                          ),
                        },
                      ]
                    : []),
                  {
                    key: 'signOut',
                    label: (
                      <a className="menu-item" onClick={handleLogout}>
                        <LogoutOutlined /> Sign out
                      </a>
                    ),
                  },
                ],
              }}
            >
              <span style={{ display: 'flex', alignItems: 'center', padding: '0px 8px' }}>
                <ProfilePicture
                  src={userProfile?.avatar_url}
                  alt={capitalize(userProfile?.first_name)}
                  isPro={isPro}
                  hideBadge={!isDataWarsHostName}
                />
                {userProfile?.first_name ? capitalize(userProfile?.first_name) : 'No name'}
              </span>
            </Dropdown>
          ),
        }
      : {
          key: 'login',
          label: location?.pathname?.startsWith('/project/') ? (
            <a onClick={() => dispatch(toggleAuthModal(true))}>Login</a>
          ) : (
            <Link to="/login">Login</Link>
          ),
        },
  ]

  const rightMenuMobileItems = isAuthenticated
    ? [
        ...(isDataWarsHostName
          ? [
              {
                key: 'dashboard',
                label: (
                  <Link to="/dashboard">
                    <span className="mobile-menu-item">
                      <HomeOutlined /> Dashboard
                    </span>
                  </Link>
                ),
              },
              {
                key: 'catalog',
                label: (
                  <Link to="/catalog?status=Published" className="mobile-menu-item">
                    <SearchOutlined /> Catalog
                  </Link>
                ),
              },
              {
                key: 'playgrounds',
                label: (
                  <Link className="mobile-menu-item" to="/playgrounds">
                    <BuildOutlined /> Playgrounds
                  </Link>
                ),
              },
              {
                key: 'datasets',
                label: (
                  <Link className="mobile-menu-item" to="/playgrounds?tab=datasets">
                    <LayerDiagonal24Regular /> Datasets
                  </Link>
                ),
              },
              ...(userProfile?.email_validated_at || emailValidationCodeIsValidated
                ? [
                    {
                      key: 'publicProfile',
                      label: (
                        <Link
                          to={`${profilesURL}${userProfile?.username}`}
                          target="_blank"
                          className="mobile-menu-item"
                        >
                          <UserOutlined /> My public profile
                        </Link>
                      ),
                    },
                  ]
                : []),
              { type: 'divider' },
              ...(isAuthenticated
                ? [
                    {
                      key: 'reportIssue',
                      label: (
                        <a onClick={() => dispatch(toggleReportIssueModal())} className="mobile-menu-item">
                          <FlagOutlined /> Report issue
                        </a>
                      ),
                    },
                  ]
                : []),
              ...(isAccountOwner || isSuperAccountOwner
                ? [
                    {
                      key: 'teams',
                      label: (
                        <Link to={teamsURL} className="mobile-menu-item">
                          <ArrowRepeatAll24Regular /> Go to Teams platform
                        </Link>
                      ),
                    },
                  ]
                : []),
              ...(isCustomer
                ? [
                    {
                      key: 'billing',
                      label: (
                        <a onClick={handleCreateBilling} className="mobile-menu-item">
                          <CreditCardOutlined /> Billing
                        </a>
                      ),
                    },
                  ]
                : []),
            ]
          : []),
        ...(voucherFulfillmentIsEnabled
          ? [
              {
                key: 'redeemVoucher',
                label: (
                  <Link to="/redeem-voucher" className="mobile-menu-item rotated">
                    <ContainerOutlined />
                    Redeem voucher
                  </Link>
                ),
              },
            ]
          : []),
        {
          key: 'signout',
          label: (
            <a onClick={handleLogout} className="mobile-menu-item">
              <LogoutOutlined /> Sign out
            </a>
          ),
        },
      ]
    : [
        {
          key: 'login',
          label: <Link to="/login">Login</Link>,
        },
      ]

  const [lastScrollPos, setLastScrollPos] = useState(0)

  const handleScroll = (evt) => {
    const scrollPosition = evt.target.scrollTop
    const elem = document.getElementsByClassName('navbar-container')?.[0]
    const progress = (evt.target.scrollTop / (evt.target.scrollHeight - window.innerHeight + 64)) * 100

    const scrollPos = lastScrollPos < scrollPosition ? 'down' : 'up'
    // const isHidden = elem.classList.contains('hidden')

    if (scrollPos === 'down') {
      elem.classList.add('hidden')
    }

    if (scrollPos === 'up') {
      elem.classList.remove('hidden')
    }

    if (progress < 5) {
      elem.classList.remove('hidden')
    }

    if (progress > 95) {
      elem.classList.add('hidden')
    }

    setLastScrollPos(scrollPosition)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true)

    return () => {
      window.removeEventListener('scroll', handleScroll, true)
    }
  }, [lastScrollPos])

  useEffect(() => {
    const elem = document.getElementsByClassName('navbar-container')?.[0]
    elem.classList.remove('hidden')
  }, [location])

  return (
    <Container
      className="navbar-container"
      isDataWarsHostName={isDataWarsHostName}
      isAssessment={!!isAssessmentPage && isAttemptStarted}
      color={currentAccount?.whitelabel_config?.enabled && currentAccount?.whitelabel_config?.primary_color}
    >
      <div className="content">
        <div className="left-content">
          {uiConfig?.showNavbarLogo && (
            <div className="logo-container">
              <Link to={'/'}>
                <img className="logo" src={Logo} alt="datawars.io logo" />
                <img className="logo-sm" src={LogoSm} alt="datawars.io logo" />
              </Link>
            </div>
          )}

          {currentAccount?.whitelabel_config?.enabled && currentAccount?.whitelabel_config?.logo_image_url && (
            <div className="logo-container">
              <Link to={currentAccount?.whitelabel_config?.logo_link_url}>
                <img
                  className="logo-whitelabel"
                  draggable={false}
                  src={currentAccount?.whitelabel_config?.logo_image_url}
                  alt={currentAccount?.name}
                />
              </Link>
            </div>
          )}

          {uiConfig?.showNavbarLeftMenu && !isModulePage && !isAssessmentPage && (
            <Menu
              id="navbar-left-menu"
              mode="horizontal"
              items={leftMenuItems}
              selectedKeys={[currentLeftMenuItem]}
              onClick={(e) => setCurrentLeftMenuItem(e.key)}
              disabledOverflow
            />
          )}
        </div>

        {isModulePage && module && (
          <div id="module-page-navbar-navigation" className="center-content">
            <Breadcrumb
              items={[
                ...(uiConfig?.showNavbarModuleNavigation && isAuthenticated
                  ? [
                      {
                        title: isCustomProject ? (
                          <span className="no-link">{module?.skill?.name || 'Back'}</span>
                        ) : (
                          <Link to={`/skill-track/${module?.skill_track?.id}`}>{module?.skill?.name || 'Back'}</Link>
                        ),
                      },
                    ]
                  : []),
                {
                  title: <span className="name">{module?.name}</span>,
                },
              ]}
            ></Breadcrumb>
          </div>
        )}

        {isAssessmentPage && module && isAttemptStarted && (
          <div id="module-page-navbar-navigation" className="center-content">
            <Breadcrumb
              items={[
                {
                  title: <span className={isAttemptStarted ? 'no-link' : 'name'}>{module?.name}</span>,
                },
                ...(isAttemptStarted
                  ? [
                      {
                        title: <span className="name">{currentObjective}</span>,
                      },
                    ]
                  : []),
              ]}
            ></Breadcrumb>
          </div>
        )}

        {!isAssessmentPage ? (
          <div className="right-content">
            {uiConfig?.showUpgradeToProUi && isAuthenticated && !isPro && !isAccountOwner && !isSuperAccountOwner && (
              <GoProButton />
            )}

            {canViewDarkMode && (
              <Tooltip title={theme === 'dark' ? 'Turn lights on' : 'Turn lights off'}>
                <Switch
                  className="theme-switch"
                  checkedChildren={<MoonOutlined />}
                  unCheckedChildren={<SunOutlined />}
                  size="small"
                  checked={theme !== 'dark'}
                  onChange={(isDark) => dispatch(setAppTheme(isDark ? 'light' : 'dark'))}
                />
              </Tooltip>
            )}

            {uiConfig?.showNavbarStreakInfo && isAuthenticated && <StreakInfo />}

            {uiConfig?.showNavbarGoToTeamsButton && (isAccountOwner || isSuperAccountOwner) && (
              <Tooltip title={'Go to Teams platform'}>
                <Button
                  className="navbar-btn"
                  type="text"
                  icon={
                    <Link to={teamsURL} className="navbar-btn">
                      <ArrowRepeatAll24Regular />
                    </Link>
                  }
                />
              </Tooltip>
            )}

            {enableCustomReportIssue && (
              <Button className="navbar-btn" onClick={handleOpenCustomReportIssueModal}>
                {customReportIssueButtonText}
              </Button>
            )}

            {uiConfig.showNavbarRightMenu && (
              <Menu id="navbar-right-menu" mode="horizontal" items={rightMenuItems} disabledOverflow />
            )}

            {uiConfig.showNavbarRightMenu && (
              <Dropdown
                menu={{
                  items: rightMenuMobileItems,
                }}
                trigger={['click']}
                overlayClassName={'navbar-right-mobile-menu'}
              >
                <span className="menu-icon">
                  <img className="menu-icon-img" src={MenuIcon} alt="menu" />
                </span>
              </Dropdown>
            )}
          </div>
        ) : (
          <div className="right-content">
            {isAttemptStarted && (
              <>
                {isAssessment && module?.expiration_time && !isAttemptFinished && isAttemptActive && <ModuleTimer />}

                <Tooltip title={'Quit assessment'}>
                  <Button
                    className="navbar-btn"
                    type="text"
                    icon={<CloseOutlined />}
                    onClick={handleStopModuleAttempt}
                  />
                </Tooltip>
              </>
            )}
          </div>
        )}
      </div>
    </Container>
  )
}

export default Navbar
