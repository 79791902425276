import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { Modal, Skeleton } from 'antd'
import { WarningOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { isDataWarsHostName } from '@/helpers/env'
import { showToast } from '@/utils/toast'
import Layout1 from '@/layouts/Layout1'
import Chat from '@/components/Chat'
import ShareModal from '@/components/ShareModal'
import ModuleContent from './components/ModuleContent'
import ModuleTitleHeader from './components/ModuleTitleHeader'
import DeviceContent from './components/DeviceContent'
import ModuleLabContent from './components/ModuleLabContent'
import ModuleAdminToolbar from './components/ModuleAdminToolbar'
import InactivityModal from './components/InactivityModal'
import ModuleTimerModal from './components/ModuleTimerModal'
import FinishedModuleModal from './components/FinishedModuleModal'
import ResetModuleModal from './components/ResetModuleModal'
import ModuleCommentsDrawer from './components/ModuleCommentsDrawer'
import ErrorCard from '@/pages/ErrorPage/components/ErrorCard'
import { toggleUpgradeToProModal } from '@/store/app/actions'
import {
  getModule,
  getAllocatedModules,
  stopModuleAttempt,
  setFinishedModuleModalOpen,
  resetModulesState,
} from '@/store/modules/actions'
import { fetchLaunch } from '@/store/launch/actions'
import { getLab, getSession, setInactivityModalOpen, resetLabsState } from '@/store/labs/actions'
import { MainContent, SecondaryContent } from './styles'

const ModulePage = () => {
  const dispatch = useDispatch()
  const { moduleId, launchId } = useParams()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(true)
  const [isFullScreen, setFullScreen] = useState(false)
  const [isCommentsDrawerOpen, setCommentsDrawerOpen] = useState(false)

  const { uiConfig, currentAccount } = useSelector((state) => state.accounts)
  const { isAuthenticated, userProfile, isLoading: isUsersLoading } = useSelector((state) => state.users)
  const {
    currentModule,
    isModuleLoading,
    isModuleAttemptLoading,
    error: modulesError,
  } = useSelector((state) => state.modules)
  const { isLoading: isLaunchLoading } = useSelector((state) => state.launch)
  const { currentLab } = useSelector((state) => state.labs)

  const accountConfig = currentAccount?.config
  const selfLaunchIsEnabled = accountConfig?.launch?.self_launch
  const apiLaunchIsEnabled = accountConfig?.launch?.api_launch

  const isPro = userProfile?.is_pro || userProfile?.groups?.includes('Pro')
  const canResumeLab = userProfile?.permissions?.includes('labs.resume_lab')

  const moduleAttempt = currentModule?.user_status?.last_module_attempt
  const isAttemptFinished = moduleAttempt?.status === 'finished'
  const isAttemptActive = moduleAttempt?.is_active
  const isAttemptExpired = moduleAttempt?.expires_at && dayjs(moduleAttempt?.expires_at).isBefore(dayjs())
  const shouldTimeOutAttempt = !isPro && !isAttemptFinished && isAttemptActive && isAttemptExpired

  const labSession = currentLab?.allocated_session
  const isLabSessionAllocated = labSession?.status === 'allocated'
  const isDeviceStopping = labSession?.devices?.some((d) => d.status === 'stopping')

  const hasLab = !!currentModule?.lab_id

  const handleModuleLoad = async (auxModuleId) => {
    setIsLoading(true)
    await dispatch(getModule(moduleId || auxModuleId))

    if (isAuthenticated) {
      await dispatch(getAllocatedModules())
    }

    setIsLoading(false)
  }

  const handleLaunchLoad = async () => {
    await dispatch(
      fetchLaunch(
        launchId,
        (data) => handleModuleLoad(data?.launch_data?.content_id),
        () => window.location.replace(apiLaunchIsEnabled ? '/session-expired' : '/'),
      ),
    )
  }

  const handleLabLoad = async (labId) => {
    setIsLoading(true)
    await dispatch(getLab(labId))
    setIsLoading(false)
  }

  const handleOpenUpgradeToProModal = () => {
    Modal.destroyAll()
    dispatch(toggleUpgradeToProModal(true))
  }

  const handleStopModuleAttempt = () => {
    Modal.confirm({
      className: 'confirm-reset-activities-dialog',
      title: 'Are you sure you want to stop this project?',
      content: (
        <>
          <p className="subtitle">
            Stopping the project will shut down your lab and prevent you from submitting new activities.
          </p>

          {uiConfig?.showUpgradeToProUi && !canResumeLab && (
            <blockquote>
              <span>
                PRO users enjoy unlimited time to resolve projects, and the ability to resume them once stopped.
              </span>

              <p>
                <a onClick={handleOpenUpgradeToProModal} style={{ color: 'initial', fontWeight: 600 }}>
                  <u>Upgrade Now</u>
                </a>
              </p>
            </blockquote>
          )}
        </>
      ),
      icon: <ExclamationCircleOutlined />,
      okText: 'Stop project',
      cancelText: 'Keep working',
      onOk: () =>
        dispatch(
          stopModuleAttempt(moduleId, currentModule?.user_status?.last_module_attempt?.id, { reason: 'manual' }),
        ),
      okButtonProps: {
        danger: true,
        type: 'primary',
        disabled: isModuleAttemptLoading,
        loading: isModuleAttemptLoading,
      },
      maskClosable: true,
    })
  }

  const renderContent = () => {
    if (isLoading || isLaunchLoading || (isModuleLoading && !currentModule))
      return (
        <div style={{ maxWidth: 800, width: '100%', overflow: 'hidden', padding: '20px 38px 20px' }}>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </div>
      )

    if (currentModule && !modulesError)
      return (
        <>
          <div className="header">
            <ModuleTitleHeader
              handleStopModuleAttempt={handleStopModuleAttempt}
              openCommentsDrawer={() => setCommentsDrawerOpen(true)}
            />
          </div>

          <ModuleContent />

          {isAuthenticated && (
            <>
              <InactivityModal />
              {!isPro && currentModule?.expiration_time && !isAttemptFinished && isAttemptActive && (
                <ModuleTimerModal />
              )}
              <ResetModuleModal />
              <FinishedModuleModal />
              {uiConfig?.showModuleShareUi && <ShareModal contentType="module" />}
              <ModuleCommentsDrawer isOpen={isCommentsDrawerOpen} onClose={() => setCommentsDrawerOpen(false)} />
            </>
          )}
        </>
      )

    return (
      <ErrorCard
        title={modulesError === 'Not found.' ? 'Project not found' : 'There was an error loading this project'}
        buttonText={isAuthenticated ? 'Back to dashboard' : 'Go to Login page'}
        buttonOnClick={isAuthenticated ? () => navigate('/') : () => navigate('/login')}
      />
    )
  }

  useEffect(() => {
    if (shouldTimeOutAttempt) {
      dispatch(stopModuleAttempt(moduleId, moduleAttempt.id, { reason: 'timed_out' }))
    }
  }, [currentLab?.allocated_session?.id])

  useEffect(() => {
    if (!currentLab) return

    let refreshSessionInterval
    if (isAuthenticated && isDeviceStopping) {
      refreshSessionInterval = setInterval(() => {
        dispatch(getSession(currentLab?.allocated_session?.id))
      }, 1000)
    }

    if (currentLab && !currentLab?.allocated_session && isAuthenticated) {
      dispatch(getAllocatedModules())
    }

    return () => {
      if (!labSession) return

      clearInterval(refreshSessionInterval)
    }
  }, [currentLab?.allocated_session]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!currentModule) return

    const title = (isDataWarsHostName ? 'DataWars' : currentAccount?.name) || 'Practice projects'
    const fullTitle = `${title} - Become an expert Data Scientist`

    document.title = `${currentModule?.name} | ${title}`
    document.querySelector("meta[property='og:title'").setAttribute('content', `${currentModule?.name} | ${title}`)
    document.querySelector("meta[name='description'").setAttribute('content', currentModule?.description)
    document.querySelector("meta[property='og:description'").setAttribute('content', currentModule?.description)

    if (currentModule?.lab_id) {
      handleLabLoad(currentModule.lab_id)
    }

    return () => {
      document.title = title
      document.querySelector("meta[property='og:title'").setAttribute('content', fullTitle)
      document
        .querySelector("meta[name='description'")
        .setAttribute('content', 'Project-based playground with +1000 ready-to-solve challenges.')
      document
        .querySelector("meta[property='og:description'")
        .setAttribute('content', 'Project-based playground with +1000 ready-to-solve challenges.')
    }
  }, [currentModule?.id]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!launchId || !apiLaunchIsEnabled) return

    dispatch(setInactivityModalOpen(false))
    dispatch(setFinishedModuleModalOpen(false))
    handleLaunchLoad()
  }, [launchId])

  useEffect(() => {
    if (!moduleId || (!isDataWarsHostName && !selfLaunchIsEnabled)) return

    dispatch(setInactivityModalOpen(false))
    dispatch(setFinishedModuleModalOpen(false))
    handleModuleLoad()

    // TODO: is this working or needs another useEffect?
    if (modulesError) {
      showToast(
        modulesError === 'Not found.' ? 'Project not found' : 'There was an error loading this project',
        'error',
      )
    }
  }, [moduleId, isAuthenticated]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      dispatch(resetModulesState())
      dispatch(resetLabsState())
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout1
      navbar
      isLoading={isAuthenticated === null}
      resizable
      isFullScreen={isFullScreen}
      drawer={<ModuleAdminToolbar />}
      mainContent={
        <>
          <MainContent>
            {renderContent()}
            {currentModule && !hasLab && !isModuleLoading && !modulesError && <ModuleLabContent />}
          </MainContent>

          {uiConfig?.showModuleAiAssistant &&
            isAttemptActive &&
            userProfile?.can_chat &&
            currentModule?.config?.ai_assistant && <Chat />}
        </>
      }
      secondaryContent={
        (!currentModule || (currentModule && hasLab)) &&
        !modulesError && (
          <SecondaryContent>
            <div className="mobile-message">
              <WarningOutlined />
              <div>
                <p>Our interactive projects are optimized for Desktop environments.</p>
                <p>
                  Preview projects on mobile, but working on them is only available on desktop. This includes: starting
                  the project and/or submitting activities.
                </p>
              </div>
            </div>

            <div className="lab-container">
              {!currentLab || isUsersLoading ? (
                <div className="loading-container">
                  <Skeleton active title={false} paragraph={{ rows: 1 }} />
                </div>
              ) : isLabSessionAllocated ? (
                <DeviceContent isFullScreen={isFullScreen} setFullScreen={setFullScreen} />
              ) : (
                <ModuleLabContent disableOverflow />
              )}
            </div>
          </SecondaryContent>
        )
      }
    />
  )
}

export default ModulePage
